<template>
  <div style="position: relative; width: 100vw; min-height: 100vh; overflow: hidden">
    <LandingBackground/>
    <div class="ytm-landing-wrapper">
      <LandingTop @scrollToFooter="goto('footer')"/>
      <div style="position: relative; width: 100%">
        <svg class="ytm-landing-button-back" @click="$router.push('/')" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="36.5" cy="36.5" r="36.5" fill="#A23DF1"/>
          <path d="M55 38.5C55.8284 38.5 56.5 37.8284 56.5 37C56.5 36.1716 55.8284 35.5 55 35.5L55 38.5ZM21.9393 35.9393C21.3536 36.5251 21.3536 37.4749 21.9393 38.0607L31.4853 47.6066C32.0711 48.1924 33.0208 48.1924 33.6066 47.6066C34.1924 47.0208 34.1924 46.0711 33.6066 45.4853L25.1213 37L33.6066 28.5147C34.1924 27.9289 34.1924 26.9792 33.6066 26.3934C33.0208 25.8076 32.0711 25.8076 31.4853 26.3934L21.9393 35.9393ZM55 35.5L23 35.5L23 38.5L55 38.5L55 35.5Z" fill="white"/>
        </svg>
        <h1 class="ytm-landing-font ytm-landing-chapter-head" style="margin-top: 4vh; margin-bottom: max(4vh, 4vw)">
          Тарифы
        </h1>
      </div>
      <div class="ytm-tariffs-table-wrapper" style="overflow: scroll">
        <table class="ytm-tariffs-table">
          <tr>
            <th/>
            <th>Начинающий</th>
            <th>Продвинутый</th>
            <th>Профессионал</th>
          </tr>
          <tr>
            <td>Сколько активных учеников за месяц? (включительно)</td>
            <td>До 3</td>
            <td>До 8</td>
            <td>Без ограничений</td>
          </tr>
          <tr>
            <td>Какими инструментами преподавателя можно пользоваться?</td>
            <td>Без ограничений</td>
            <td>Без ограничений</td>
            <td>Без ограничений</td>
          </tr>
          <tr>
            <td>Учёт доходов и расписание</td>
            <td>Есть</td>
            <td>Есть</td>
            <td>Есть</td>
          </tr>
          <tr>
            <td>Чаты</td>
            <td>Есть</td>
            <td>Есть</td>
            <td>Есть</td>
          </tr>
          <tr>
            <td>Можно ли создавать свою базу вопросов и тестов?</td>
            <td>Нет</td>
            <td>Да</td>
            <td>Да</td>
          </tr>
          <tr>
            <td>Можно ли проводить групповые занятия?</td>
            <td>Нет</td>
            <td>Нет</td>
            <td>Да</td>
          </tr>
          <tr>
            <td>Внесение изменений в занятие</td>
            <td>В течение 7 дней</td>
            <td>В течение 30 дней</td>
            <td>Без ограничений</td>
          </tr>
          <tr>
            <td>Ограничения по хранению файлов</td>
            <td>До 250 МБ</td>
            <td>До 2 ГБ</td>
            <td>До 15 ГБ</td>
          </tr>
          <tr>
            <td style="border: none">Стоимость в месяц</td>
            <td class="ytm-tariffs-cost" style="border: none">299 рублей</td>
            <td class="ytm-tariffs-cost" style="border: none">699 рублей</td>
            <td class="ytm-tariffs-cost" style="border: none">1299 рублей</td>
          </tr>
        </table>
      </div>
      <JoinUs/>
      <Footer ref="footer"/>
    </div>
  </div>
</template>

<script>
import LandingBackground from "../Background";
import LandingTop from "../Navbar";
import Footer from "../Footer";
import JoinUs from "../JoinUs";

export default {
  name: 'TariffsPage',
  components: {JoinUs, LandingBackground, LandingTop, Footer},
    methods: {
        goto(ref_name) {
            let element;
            switch (ref_name) {
                case 'footer':
                    element = this.$refs.footer.$el; break;
            }
            const top = element.offsetTop;
            window.scrollTo(0, top);
        },
    },
};
</script>

<style scoped>
.ytm-tariffs-table-wrapper {
  margin-bottom: 6vh;
  padding: max(3vh, 3vw) max(3vw, 2vh);
  border-radius: 10%;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.6);
}

.ytm-tariffs-table {
  font-family: 'Montserrat', sans-serif;
  font-size: max(1.2vw, 0.5rem);
  font-weight: 400;
  border-collapse: collapse;
  width: 100%;
}

.ytm-tariffs-table td, th {
  border-bottom: 1px solid #191847;
  text-align: left;
  padding: 1.2vw;
}

.ytm-tariffs-cost {
  font-size: max(1.25vw, 0.6rem);
  font-weight: 500;
}
</style>